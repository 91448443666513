<template>
  <div id="EmployeeAllocationPlanning">
    <v-container fluid app>
      <ZnapHotGrid
        :requiredOptions="requiredOptions"
        :options="options"
        :idPlanningFunction="idPlanningFunction"
      >
      </ZnapHotGrid>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import ZnapHotGrid from "@/components/znap/HotGridEmployee.vue";

export default {
  name: "EmployeeAllocationPlanning",

  components: {
    ZnapHotGrid,
  },

  props: {
    idPlanningFunction: { type: Number, required: false },
  },

  data: () => ({
    requiredOptions: [
      {
        column: "id_event",
        is: "ComboBox",
        items: [],
        multiple: false,
        label: "Evento",
        required: true,
        filterable: false,
        endpoint: [Vue.prototype.$ipEvent, "event"],
      },
    ],

    options: [
      {
        column: "id_employee",
        columnToRemove: "employee_name",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Nome",
        required: false,
        filterable: true,
        dynamicFilter: true,
        endpoint: [Vue.prototype.$ipOrganization, "employee"],
        cols: "3",
        visible: true,
        conditions: [],
      },
      {
        column: "id_department",
        columnToRemove: "department_description",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Departamento",
        required: false,
        filterable: true,
        dynamicFilter: true,
        endpoint: [Vue.prototype.$ipOrganization, "cost-center"],
        cols: "3",
        visible: true,
        conditions: [
          {
            AndOr: "AND",
            column: "id_cost_center_type",
            operator: "=",
            value: 3,
          },
          {
            AndOr: "AND",
            column: "id_company_group",
            operator: "=",
            value: null,
            getValueFrom: "event",
          },
        ],
      },
      {
        column: "id_employee_role",
        columnToRemove: "role",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Descrição do cargo do colaborador",
        required: false,
        filterable: false,
        endpoint: [Vue.prototype.$ipOrganization, "role"],
        cols: "3",
        visible: true,
      },
      {
        column: "id_role",
        columnToRemove: "role",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Descrição do cargo no projeto",
        required: false,
        filterable: false,
        endpoint: [Vue.prototype.$ipOrganization, "role"],
        cols: "3",
        visible: true,
      },
      {
        column: "id_product",
        columnToRemove: "product_name",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Nome do projeto",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipProduct, "product-sales-planning"],
        cols: "3",
        visible: true,
      },
      {
        column: "id_sales_force",
        columnToRemove: "sales_force_name",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Gerente executivo",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipSales, "sales-force"],
        cols: "3",
        conditions: [
          {
            AndOr: "AND",
            column: "id_sales_force_type",
            operator: "=",
            value: 6,
          },
        ],
        visible: true,
      },
      {
        column: "id_customer_group",
        columnToRemove: "customer_group",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Conta cliente",
        required: false,
        filterable: false,
        endpoint: [Vue.prototype.$ipCustomer, "customer-group"],
        cols: "3",
        visible: true,
      },
      {
        column: "id_sales_channel",
        columnToRemove: "sales_channel",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Canal de vendas",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipSales, "sales-channel"],
        cols: "3",
        conditions: [
          {
            AndOr: "AND",
            column: "id_sales_channel_type",
            operator: "=",
            value: 1,
          },
        ],
        visible: true,
      },
      {
        column: "id_status",
        columnToRemove: "status",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "Situação",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipUnit, "status"],
        cols: "3",
        conditions: [
          {
            AndOr: "AND",
            column: "id_status_type",
            operator: "=",
            value: 6,
          },
        ],
        visible: true,
      },
      {
        column: "id_hr_classification",
        columnToRemove: "hr_classification",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "RH",
        required: false,
        filterable: false,
        endpoint: [Vue.prototype.$ipOrganization, "hr-classification"],
        cols: "3",
        visible: true,
      },
      {
        column: "id_cost_center",
        columnToRemove: "cd_cost_center",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "CDC Netsuite",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipOrganization, "cost-center"],
        cols: "3",
        conditions: [
          {
            AndOr: "AND",
            column: "id_company_group",
            operator: "=",
            value: 1,
          },
          {
            AndOr: "AND",
            column: "id_cost_center_type",
            operator: "=",
            value: 1,
          },
        ],
        visible: true,
      },
      {
        column: "id_sales_force_bp",
        columnToRemove: "sales_force_bp_name",
        is: "ComboBox",
        items: [],
        multiple: true,
        label: "BP",
        required: false,
        filterable: true,
        endpoint: [Vue.prototype.$ipSales, "sales-force"],
        cols: "3",
        conditions: [
          {
            AndOr: "AND",
            column: "id_sales_force_type",
            operator: "=",
            value: 7,
          },
        ],
        visible: true,
      },
      {
        column: "exclude_from_ratio",
        is: "CheckBox",
        label: "Exclui do rateio",
        visible: true,
      },
    ],
  }),
};
</script>

<style scoped>
</style>