<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular"
                    style="display: inline-block; max-width: 512px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                >
                    {{ `${tableName}${event && event !== -1 ? ' - ' + event.text : ''}` }}
                </span>

                <v-spacer></v-spacer>

                <v-tooltip top v-if="selectedRowDuplicate">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary" dark
                            v-on="on"
                            class="mr-4 pa-0"
                            min-width="48px"
                            @click="newEmployeeAllocationDialog = true"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar linha selecionada</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noEventSelected"
                            @click="selectedRowDuplicate = null, newEmployeeAllocationDialog = true"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Nova alocação</span>
                </v-tooltip>

                <v-tooltip top v-if="createEmployeePermission">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noEventSelected"
                            @click="newEmployeeDialog = true"
                        >
                            <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo funcionário</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noEventSelected || event.id_event_type === 4 || event.id_event_type === 1"
                            @click="updateMonthsDialog = true"
                        >
                            <v-icon>mdi-update</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar meses realizados</span>
                </v-tooltip>

                <v-dialog v-model="updateMonthsDialog" max-width="450px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="title">Você deseja realizar essa atualização?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="updateMonthsDialog = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="updateMonths()" :loading="loadingUpdateMonths">Continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="idPlanningFunction && idPlanningFunction === 16">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="openDialogCopyData()"
                        >
                            <v-icon>mdi-database-arrow-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Copiar dados</span>
                </v-tooltip>

                <v-dialog v-model="dialogCopyData" max-width="900" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title>
                                <span>Copiar dados</span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="closeDialogCopyData()"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-container class="pa-4 py-8">
                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                        name="event"
                                        v-model="selectedCopyDataEvent"
                                        label="Evento do mapa de alocação"
                                        :items="copyDataEvents"
                                        @change="selectCopyDataEvent()"
                                        item-value="text"
                                        return-object
                                        class="pa-0 ma-0 mb-2"
                                        clearable
                                        @click:clear="clearCopyDataEvent()"
                                        :loading="loadingCopyDataEvents"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                        name="bp"
                                        v-model="selectedCopyDataBP"
                                        label="BP"
                                        :items="copyDataBPs"
                                        @change="selectCopyDataBP()"
                                        item-value="text"
                                        return-object
                                        class="pa-0 ma-0 mb-2"
                                        :loading="loadingCopyDataBPs"
                                        :disabled="!selectedCopyDataEvent"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                        name="project"
                                        v-model="selectedCopyDataProject"
                                        label="Projetos/produtos"
                                        :items="copyDataProjects"
                                        item-value="text"
                                        return-object
                                        class="pa-0 ma-0 mb-2"
                                        :loading="loadingCopyDataProjects"
                                        :disabled="!selectedCopyDataEvent"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                        </v-container>

                        <div class="light" style="position: sticky; bottom: 0; width: 100%">
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions class="px-6 py-6">
                                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="closeDialogCopyData()"
                                    color="primary"
                                    text
                                    class="mr-4"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="handleCopySave()"
                                    :loading="copySaving"
                                    :disabled="!selectedCopyDataEvent"
                                >
                                    Salvar
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="setRefresh()"
                            :disabled="noEventSelected || noDisplayFilterSelected"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="refresh()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="showTab = !showTab"
                            :disabled="noEventSelected"
                        >
                            <v-icon>{{ showTab ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ showTab ? 'Ocultar filtros' : 'Exibir filtros' }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-4 pa-0"
                            @click="save()"
                            v-bind="attrs"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'secondary' : 'primary'"
                            :disabled="noEventSelected || readOnly || !editedRows.length"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noEventSelected || readOnly || unsavedChanges"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="noEventSelected"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="noEventSelected"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="noEventSelected || !selectedPlanningGroup || !selectedProduct || selectedPlanningGroup === 15"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"> Aprovar nível - {{ actualLevel.id_level }} </span>
                                <span v-else-if="actualLevel.id_status === 4"> Todos os níveis aprovados </span>
                                <span v-else> Nenhum nível cadastrado </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="requiredOptions.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedProduct"
                                        :items="productItems"
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"
                                        label="Projeto/produto"
                                        readonly
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_level === 1 && actualLevel.id_status !== 4">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_status === 4">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApprovalAll = true"
                            :disabled="noEventSelected || !selectedPlanningGroup || allActualLevels.length < 1 || selectedPlanningGroup === 15"
                        >
                            <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar múltiplos</span>
                </v-tooltip>

                <v-dialog v-model="dialogApprovalAll" max-width="900" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title>
                                <span> Aprovar múltiplos </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="closeDialogApprovalAll()"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-space-between wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="event"
                                        :items="requiredOptions.find(option => option.column === 'id_event').items"
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        @change="selectEvent()"
                                        return-object
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                </v-flex>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6>
                                    <v-menu
                                        top
                                        close-on-click
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-card class="mr-1" elevation="0" color="transparent">
                                                <v-btn v-bind="attrs" v-on="on" class="my-2" color="error" :loading="loadingApproval" :disabled="loadingApproval || disableReproveAll || selectAllToApprove" block>
                                                    Reprovar {{ selected.length > 0 ? selected.length : 'todos' }} projeto/produto(s)
                                                </v-btn>
                                            </v-card>
                                        </template>

                                        <v-list>
                                            <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn @click="approveAll(0)" class="my-2" color="primary" block>
                                                        Sim
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn class="my-2" color="primary" block text>
                                                        Não
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-checkbox
                                        v-model="selectAllToReprove"
                                        :disabled="selectAllToApprove"
                                        :label="'Selecionar todos liberados para reprovar'"
                                        class="mx-2"
                                    />
                                </v-flex>
                                <v-flex xs12 sm12 md6>
                                    <v-menu
                                        top
                                        close-on-click
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-card class="ml-1" elevation="0" color="transparent">
                                                <v-btn v-bind="attrs" v-on="on" class="my-2" color="success" :loading="loadingApproval" :disabled="loadingApproval || disableApproveAll || selectAllToReprove" block>
                                                    Aprovar {{ selected.length > 0 ? selected.length : 'todos' }} projeto/produto(s)
                                                </v-btn>
                                            </v-card>
                                        </template>

                                        <v-list>
                                            <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn @click="approveAll(1)" class="my-2" color="primary" block>
                                                        Sim
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn class="my-2" color="primary" block text>
                                                        Não
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-checkbox
                                        v-model="selectAllToApprove"
                                        :disabled="selectAllToReprove"
                                        :label="'Selecionar todos liberados para aprovar'"
                                        class="mx-2"
                                    />
                                </v-flex>
                                <v-flex xs12>
                                    <v-data-table
                                        v-model="selected"
                                        show-select
                                        :headers="headersApprovalAll"
                                        :items="allActualLevels"
                                        :loading="loading"
                                        item-key="id"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <template v-slot:[`header.data-table-select`]></template>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actualLevel="{ item }">
                                            <v-layout v-if="item.actualLevel">
                                                <v-flex class=" mt-5">
                                                    {{ item.actualLevel.approval_flow_level_description }}
                                                </v-flex>
                                                <v-spacer></v-spacer>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="item.justification"
                                                        class="mx-2 my-n1"
                                                        label="Justificativa"
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-btn 
                                                    @click="selectedPlanningGroup = item.id_planning_group, productSelected = item.id_product, justification = item.justification, approvalSelect(0)"
                                                    fab
                                                    depressed
                                                    x-small
                                                    class="error mr-2 mt-3"
                                                    :loading="loadingApproval"
                                                    :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_level === 1 && item.actualLevel.id_status !== 4"
                                                >
                                                    <v-icon small>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    @click="selectedPlanningGroup = item.id_planning_group, productSelected = item.id_product, justification = item.justification, approvalSelect(1)"
                                                    fab
                                                    depressed
                                                    x-small 
                                                    class="success mr-2 mt-3"
                                                    :loading="loadingApproval"
                                                    :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_status === 4"
                                                >
                                                    <v-icon small>
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-dialog>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="secondary" text @click="$router.push('/')" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider :class="showTab ? 'mb-1' : 'mb-4'"></v-divider>           

            <v-row v-if="showTab" no-gutters class="d-flex justify-start align-center mb-1" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        height="24px"
                    >
                        <v-tabs-slider :color="noAnalyseHeaders || noEventSelected ? 'transparent': 'primary'"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                            :disabled="noAnalyseHeaders || noEventSelected"
                        >
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>

                <v-spacer></v-spacer>

                <v-col v-if="readOnly"
                    class="d-flex justify-end"
                    style="height: 24px !important; cursor: pointer !important;"
                >
                    <v-menu offset-y
                        style="z-index: 1000 !important;"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on"
                                class="d-flex justify-end align-start"
                            >
                                <div class="d-flex align-center">
                                    <v-icon small color="secondary" class="mr-2">mdi-alert-decagram</v-icon>
                                    <span class="secondary--text text-caption">Somente consulta</span>
                                </div>
                            </div>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-list-item dense
                                    v-for="(item, i) in readOnlyRules" :key="i"
                                    class="ma-0 pa-0"
                                >
                                    <v-list-item-icon class="mx-0 mr-2 pa-0">
                                        <v-icon color="secondary">mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-caption font-weight-regular">{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tab" v-show="showTab">
                <v-tab-item>
                    <v-row align="center" no-gutters class="pt-3">

                        <!-- EVENTO -->
                        <v-autocomplete
                            label="Evento"
                            :items="requiredOptions.find(option => option.column === 'id_event').items"
                            item-value="text"
                            return-object
                            class="pa-0 mr-4"
                            v-model="event"
                            @click="backupSelectEvent = event"
                            @change="selectEvent()"
                            :loading="loadingEvents"
                            style="max-width: 300px !important; z-index: 1000 !important;"
                            :disabled="loading"
                        />

                        <v-dialog v-model="dialogSelectEvent" max-width="850px"
                            style="z-index: 5000 !important;"
                        >
                            <v-card>
                                <div class="d-flex justify-center">
                                    <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                                </div>
                                <v-card-actions class="px-6 pb-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="closeDialogSelectEvent()" class="mr-4">Cancelar</v-btn>
                                    <v-btn color="secondary" text @click="selectEvent(false)" class="mr-4">Continuar sem salvar</v-btn>
                                    <v-btn color="primary" @click="selectEvent(true)">Salvar e continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- GRUPO DE PLANEJAMENTO -->
                        <v-autocomplete
                            label="Grupo de planejamento"
                            :items="planningGroups"
                            item-value="text"
                            return-object
                            class="pa-0 mr-4"
                            v-model="planningGroupOption"
                            @change="selectPlanningGroup()"
                            :disabled="!event || loading"
                            :loading="loadingPlanningGroups"
                            :clearable="true"
                            style="max-width: 300px !important; z-index: 1000 !important;"
                        />

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <v-col class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1month"
                                    @click="showAllMonths(1)"
                                    :disabled="noEventSelected"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="noEventSelected"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="260px">
                                        <v-select
                                            v-model="year1months"
                                            :items="months"
                                            label="Escolha os meses"
                                            :item-text="item => item.text"
                                            @change="showMonth(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1month = true, showAllMonths(1)"
                                                :disabled="year1months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>
                            </div>

                            <v-divider vertical class="mr-4 mb-4"></v-divider>

                            <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2month"
                                    @click="showAllMonths(2)"
                                    :disabled="noEventSelected"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="noEventSelected"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2months"
                                            :items="months"
                                            :item-text="item => item.text"
                                            @change="showMonth(2)"
                                            label="Escolha os meses"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2month = true, showAllMonths(2)"
                                                :disabled="year2months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>
                            </div>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="mt-2">
                        <v-col v-for="option in options" :key="option.column" :cols="option.cols">
                            <v-autocomplete v-if="option.column === 'id_cost_center'"
                                :label="option.label"
                                :items="option.items"
                                :item-text="item => item.text"
                                return-object
                                class="pa-0 mr-4"
                                style="z-index: 1000 !important;"
                                @click="loadOptionItems(option)"
                                @change="setSelectedFilter(option, $event)"
                                :loading="isLoadingOptionItems(option)"
                                clearable
                                multiple
                                :hide-no-data="true"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="mr-2">{{ item.cd_cost_center }}</span>
                                    <span v-if="index === 1" class="pt-1 text-caption primary--text">{{ `(+ ${filterConditions.find(f => f.option === option.column).selectedItems.length - 1})`}}</span>
                                </template>
                            </v-autocomplete>

                            <v-autocomplete v-else-if="option.multiple"
                                :label="option.label"
                                :items="option.items"
                                item-text="text"
                                return-object
                                class="pa-0 mr-4"
                                style="z-index: 1000 !important;"
                                @click="loadOptionItems(option)"
                                @change="setSelectedFilter(option, $event)"
                                :loading="isLoadingOptionItems(option)"
                                clearable
                                multiple
                                :hide-no-data="true"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="mr-2">{{ item.text }}</span>
                                    <span v-if="index === 1" class="pt-1 text-caption primary--text">{{ `(+ ${filterConditions.find(f => f.option === option.column).selectedItems.length - 1})`}}</span>
                                </template>
                            </v-autocomplete>

                            <v-autocomplete v-else-if="option.is === 'ComboBox'"
                                :label="option.label"
                                :items="option.items"
                                item-text="text"
                                return-object
                                class="pa-0 mr-4"
                                style="z-index: 1000 !important;"
                                @click="loadOptionItems(option)"
                                @change="setSelectedFilter(option, $event)"
                                :loading="isLoadingOptionItems(option)"
                                clearable
                                :hide-no-data="true"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
                        <v-col cols="2" @click="handleSetAllHiddenColumns()" style="cursor: pointer">
                            <v-icon class="mr-2" :color="setAllHiddenColumnsColor">{{ setAllHiddenColumnsIcon }}</v-icon>
                            <span class="text-subtitle-2">{{ setAllHiddenColumnsText }}</span>
                        </v-col>

                        <v-col v-for="option in options" :key="option.column"
                            class="d-flex justify-start"
                            cols="2"
                        >
                            <v-checkbox
                                :ripple="false"
                                v-model="option.visible"
                                :name="option.column"
                                :label="option.label"
                                class="pa-0 ma-0"
                                hide-details
                            />
                        </v-col>

                        <v-col cols="2"
                            class="d-flex justify-start"
                        >
                            <v-checkbox
                                :ripple="false"
                                v-model="showColorAllocation"
                                name="showColorAllocation"
                                label="Alocação por cores"
                                class="pa-0 ma-0"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
                        <v-col
                            class="d-flex justify-start"
                        >
                            <v-checkbox
                                :ripple="false"
                                v-model="filterExitDate"
                                label="Ocultar colaboradores desligados em anos anteriores ao ano do evento"
                                class="pa-0 ma-0"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="gridContainerHeight" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="2"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="50"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="payload"
        />

        <v-dialog v-model="newEmployeeAllocationDialog"
            persistent
            no-click-animation
            style="z-index: 5000 !important; overflow: hidden !important;"
        >
            <ZnapNewEmployeeAllocationDialog
                :newEmployeeAllocationDialog="newEmployeeAllocationDialog"
                :objectData="objectData"
                :columns="columns"
                :readOnlyColumns="readOnlyColumns"
                :colHeaders="colHeaders"
                :event="event"
                :windowSize="windowSize"
                :selectedRowDuplicate="selectedRowDuplicate"
                :costCenterItems="costCenterItems"
                :productItems="productItems"
                :salesForceItems="salesForceItems"
                :salesForceBPItems="salesForceBPItems"
                :customerGroupItems="customerGroupItems"
                :roleItems="roleItems"
                :statusItems="statusItems"
                :hrClassificationItems="hrClassificationItems"
                :filterExitDate="filterExitDate"
                @closeNewEmployeeAllocationDialog="closeNewEmployeeAllocationDialog($event)"
            />
        </v-dialog>

        <v-dialog v-model="newEmployeeDialog" 
            max-width="900px"
            persistent
            no-click-animation
            style="z-index: 5000 !important; overflow: hidden !important;"
        >
            <ZnapNewEmployeeDialog
                :newEmployeeDialog="newEmployeeDialog"
                @closeNewEmployeeDialog="closeNewEmployeeDialog($event)"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import CommentsMenu from '@/components/znap/CommentsMenuEmployee.vue'
import moment from 'moment'
import ZnapNewEmployeeAllocationDialog from '@/components/znap/ZnapNewEmployeeAllocationDialog.vue'
import ZnapNewEmployeeDialog from '@/components/znap/ZnapNewEmployeeDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapNewEmployeeAllocationDialog, ZnapNewEmployeeDialog
    },

    props: {
        requiredOptions: { type: Array, required: true },
        options: { type: Array, required: true },
        idPlanningFunction: { type: Number, required: true }
    },

    data() {
        return {
            tab: 0,
            tabItems: ['Evento', 'Filtros', 'Exibir', 'Opções'],
            showTab: true,

            analyseHeaders: [],
            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterSelection: () => {
                    if (this.hotInstance) {
                        let cellCoords = this.hotInstance.getSelectedLast()

                        if (cellCoords) {
                            let firstRow = cellCoords[0]
                            let lastRow = cellCoords[2]

                            if (firstRow > 0 && firstRow === lastRow) {
                                this.selectedRowDuplicate = firstRow
                            } else {
                                this.selectedRowDuplicate = null
                            }
                        } else {
                            this.selectedRowDuplicate = null
                        }
                    }
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(c => {
                            let row = c[0]
                            let column = c[1]
                            let oldVal = c[2]
                            let newVal = c[3]

                            if (newVal === oldVal) {
                                return
                            }

                            if (newVal !== oldVal) {
                                if (column === 'role') {
                                    let foundOption = this.options.find(o => o.column === 'id_role')
                                    let foundItemId = null
                                    if (newVal) {
                                        foundItemId = foundOption.items.find(i => i.text === newVal).id
                                    }
                                    
                                    // this.objectData[row].id_role = foundItemId
                                    this.hotInstance.setDataAtRowProp(row, 'id_role', foundItemId)
                                }

                                if (column === 'status') {
                                    let foundOption = this.options.find(o => o.column === 'id_status')
                                    let foundItemId = null
                                    if (newVal) {
                                        foundItemId = foundOption.items.find(i => i.text === newVal).id
                                    }

                                    // this.objectData[row].id_status = foundItemId
                                    this.hotInstance.setDataAtRowProp(row, 'id_status', foundItemId)
                                }

                                if (column === 'hr_classification') {
                                    let foundOption = this.options.find(o => o.column === 'id_hr_classification')
                                    let foundItemId = null
                                    if (newVal) {
                                        foundItemId = foundOption.items.find(i => i.text === newVal).id
                                    }
                                    
                                    // this.objectData[row].id_hr_classification = foundItemId
                                    this.hotInstance.setDataAtRowProp(row, 'id_hr_classification', foundItemId)
                                }

                                if (column === 'product_name') {
                                    let foundOption = this.options.find(o => o.column === 'id_product')

                                    if (newVal) {
                                        let foundItem = foundOption.items.find(i => i.text === newVal)
                                        this.hotInstance.setDataAtRowProp(row, 'id_product', foundItem.id_product)
                                        this.objectData[row].id_planning_group = foundItem.id_planning_group

                                        let costCenterItems = this.options.find(o => o.column === 'id_cost_center').items
                                        let foundCostCenter = costCenterItems.find(i => i.id_product === foundItem.id_product)

                                        this.hotInstance.setDataAtRowProp(row, 'id_cost_center', foundCostCenter?.id || null)
                                        this.hotInstance.setDataAtRowProp(row, 'cd_cost_center', foundCostCenter?.cd_cost_center || null || null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_customer_group', foundCostCenter?.id_customer_group || null)
                                        this.hotInstance.setDataAtRowProp(row, 'customer_group', foundCostCenter?.customer_group || null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_channel', foundCostCenter?.id_sales_channel || null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_channel', foundCostCenter?.sales_channel || null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_force', foundCostCenter?.id_sales_force || null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_force_name', foundCostCenter?.sales_force_name || null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_force_bp', foundCostCenter?.id_sales_force_bp || null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_force_bp_name', foundCostCenter?.sales_force_bp_name || null)
                                    } else {
                                        this.hotInstance.setDataAtRowProp(row, 'id_product', null)
                                        this.objectData[row].id_planning_group = null
                                        this.hotInstance.setDataAtRowProp(row, 'id_cost_center', null)
                                        this.hotInstance.setDataAtRowProp(row, 'cd_cost_center', null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_customer_group', null)
                                        this.hotInstance.setDataAtRowProp(row, 'customer_group', null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_channel', null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_channel', null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_force', null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_force_name', null)
                                        this.hotInstance.setDataAtRowProp(row, 'id_sales_force_bp', null)
                                        this.hotInstance.setDataAtRowProp(row, 'sales_force_bp_name', null)
                                    }
                                }

                                if (column.includes("value")) {
                                    this.hotInstance.setDataAtRowProp(row, column, newVal)
                                }
        
                                let physicalRow = this.hotInstance.toPhysicalRow(row)
                                if (!this.editedRows.includes(physicalRow)) {
                                    this.editedRows.push(physicalRow)
                                }
                            }
                        })
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData.replaceAll('null', ''));
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    if (row === 0) {
                        cellProperties.className = ' total-column font-bold border-bottom-1'
                        cellProperties.readOnly = true
                        cellProperties.numericFormat = {
                            pattern: {
                                thousandSeparated: true,
                                negative: "parenthesis",
                                mantissa: 2
                            },
                            culture: ptBR.languageCode
                        }
                    } else {
                        if (this.showColorAllocation) {
                            if (prop.includes('value') && prop !== 'value00' && !this.readOnlyColumns.includes(col)) {
                                let accumulatedAllocation = this.calculateAccumulatedAllocation(this.objectData[row].id_employee, prop)
    
                                if (accumulatedAllocation === 100 || !accumulatedAllocation) {
                                    cellProperties.className = this.removeAllocationColors(cellProperties.className)
                                } else if (accumulatedAllocation > 100) {
                                    cellProperties.className += ' allocation-error-cell'
                                } else if (accumulatedAllocation < 100) {
                                    cellProperties.className += ' allocation-warning-cell'
                                }
                            }
                        }
                    }

                    if (this.readOnly) {
                        cellProperties.readOnly = this.readOnly
                    }

                    return cellProperties
                }
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: { columns: [], copyPasteEnabled: false },
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Editar'
                    },
                    hidden() {
                        let hidden = false
                        const cellCoords = this.getSelectedLast()
                        const firstRow = cellCoords[0]
                        const lastRow = cellCoords[2]
                        if (firstRow < 1 || firstRow !== lastRow) {
                            hidden = true
                        }
                        return hidden
                    },
                    callback: (key, selection) => {
                        this.selectedRowDuplicate = selection[0].start.row
                        this.newEmployeeAllocationDialog = true
                    }
                }
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            // Options
            payload: {
                id_event: null,
                id_sales_channel: null,
                id_account_group_version: null,
                id_planning_group: null,
                id_product: null,
                id_customer_group: null,
                id_sales_force: null,
            },

            // !

            event: null,
            planningGroupOption: null,
            planningGroup: null,

            statusItems: [],
            selectedStatusItems: [],

            costCenterItems: [],
            selectedCostCenterItems: [],

            productItems: [],
            selectedProductItems: [],

            salesForceItems: [],
            selectedSalesForceItems: [],

            salesForceBPItems: [],
            selectedSalesForceBPItems: [],

            customerGroupItems: [],
            selectedCustomerGroupItems: [],

            roleItems: [],
            selectedRoleItems: [],

            hrClassificationItems: [],
            selectedHrClassificationItems: [],

            showExcludeFromRatio: true,

            year1: null,
            year2: null,

            disableEdition: false,
            readOnlyRules: [],

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],

            // Unsaved changes
            editedRows: [],
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogSelectEvent: false,
            backupSelectEvent: null,
            lastRefreshParams: null,

            windowSize: { x: 0, y: 0 },
            loading: true,
            loadingEvents: true,
            loadingPlanningGroups: false,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },
            disabledPreviousProduct: true,

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            loadingOptionItems: [],
            filterConditions: [],
            selectedDisplayFilters: [],

            newEmployeeAllocationDialog: false,
            selectedRowDuplicate: null,

            newEmployeeDialog: false,
            updateMonthsDialog: false,
            loadingUpdateMonths: false,
            showColorAllocation: false,

            filterExitDate: true,

            // workflow
            readOnly: false,
            readOnlyApproval: false,
            dialogApproval: false,
            dialogApprovalAll: false,
            selected: [],
            doRefresh: false,
            loadingApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            headersApprovalAll: [
                {
                    text: 'ID', value: 'id_product'
                },
                {
                    text: 'Projeto/produto', value: 'text'
                },
                {
                    text: 'Nível', value: 'actualLevel'
                }
            ],
            levels: [],
            allActualLevels: [],
            planningGroups: [],
            selectedPlanningGroup: null,
            selectedProduct: null,
            selectedEvent: null,
            productSelected: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true,
            selectAllToApprove: false,
            selectAllToReprove: false,

            dialogCopyData: false,
            copySaving: false,

            copyDataEvents: [],
            selectedCopyDataEvent: null,
            loadingCopyDataEvents: false,

            copyDataBPs: [],
            selectedCopyDataBP: null,
            loadingCopyDataBPs: false,

            copyDataProjects: [],
            selectedCopyDataProject: null,
            loadingCopyDataProjects: false,

            crudPermission: true,
            createPermission: true,
            updatePermission: true,
            deletePermission: true,
            createEmployeePermission: true,
        }
    },

    created() {
        let routePath = this.$route.path

        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }

        const employeePermissions = this.$getPermissions('/employee')
        this.createEmployeePermission = employeePermissions.crudPermission || employeePermissions.createPermission
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
        this.addDoubleClickHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },

    destroyed() {
        this.removePreventCloseHandler()
        this.removeDoubleClickHandler()
    },

    watch: {
        event (val) {
            this.loadingPlanningGroups = true

            // this.selectedEvent = val.id
            try {
                this.$http.post(this.$ipApprovalFlow + 'planning-group' + '/list-options',
                    {
                        filter: {
                            conditions: [
                                {
                                    AndOr: "AND",
                                    column: "id_planning_group_version",
                                    operator: "=",
                                    value: val.id_planning_group_version
                                }
                            ]
                        }
                    }
                ).then((res) => {
                    this.planningGroups = res.data.rows
                    this.loadingPlanningGroups = false
                })
            } catch (err) {
                this.$fnError(err)
                this.loadingPlanningGroups = false
            }
        },

        planningGroupOption (val) {
            if (val){
                this.levels = []
                this.approvals = []
            } else {
                this.readOnlyApproval = false
                this.levels = []
                this.approvals = []
            }
        },

        idPlanningFunction: {
            immediate: true,
            handler(newVal, oldVal) {
                if (!oldVal) {
                    return
                }

                if (newVal !== oldVal) {
                    this.init()
                }
            }
        },

        selectAllToApprove (val) {
            this.selected = []
            if (val) {
                this.selected = this.allActualLevels.filter(level => level.actualLevel.id_status !== 4 && level.actualLevel.enable)
            }
        },
        selectAllToReprove (val) {
            this.selected = []
            if (val) {
                this.selected = this.allActualLevels.filter(level => level.actualLevel.id_level !== 1 && level.actualLevel.enable || level.actualLevel.id_status === 4 && level.actualLevel.enable)
            }
        },
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        gridContainerHeight() {
            if (this.showTab) {
                if (this.tab === 0) {
                    return this.windowSize.y - 64 - 192
                }
    
                if (this.tab === 1) {
                    return this.windowSize.y - 64 - 303
                }
    
                if (this.tab === 2) {
                    return this.windowSize.y - 64 - 230
                }

                if (this.tab === 3) {
                    return this.windowSize.y - 64 - 166
                }
            }

            return this.windowSize.y - 64 - 106
        },

        unsavedChanges() {
            if (this.editedRows.length > 0) {
                return true
            } else {
                return false
            }
        },

        hasMultipleSelection() {
            return false
        },

        isNotShowingRequiredColumn() {
            let isNotShowingRequiredColumn = false

            if (!this.options.find(i => i.column === 'id_employee').visible) {
                isNotShowingRequiredColumn = true
            }

            return isNotShowingRequiredColumn
        },

        disableLoadGrid() {
            return false
        },

        hasEmptyRequiredFilter() {
            return false
        },

        noAnalyseHeaders() {
            return this.analyseHeaders.length ? false : true
        },

        noEventSelected() {
            return !this.event || this.event === -1 ? true : false
        },

        noDisplayFilterSelected() {
            let found = this.options.find(o => o.visible === true)
            return found === -1
        },

        setAllHiddenColumnsText() {
            let found = this.options.find(o => o.visible)
            return found ? 'DESMARCAR TODOS' : 'SELECIONAR TODOS'
        },

        setAllHiddenColumnsIcon() {
            let found = this.options.find(o => o.visible)
            return found ? 'mdi-close-box' : 'mdi-checkbox-blank-outline'
        },

        setAllHiddenColumnsColor() {
            let found = this.options.find(o => o.visible)
            return found ? 'primary' : ''
        },

        disableApproveAll () {
            let disable = []

            if (this.selected.length > 0) {
                if (this.selected.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.selected.find(level => level.actualLevel.id_status === 4)) disable.push(true)
            } else {
                if (this.allActualLevels.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.allActualLevels.find(level => level.actualLevel.id_status === 4)) disable.push(true)
            }

            if (this.allActualLevels.length < 1) disable.push(true)

            return disable.includes(true)
        },
        disableReproveAll () {
            let disable = []

            if (this.selected.length > 0) {
                if (this.selected.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.selected.find(level => level.actualLevel.id_level === 1 && level.actualLevel.id_status !== 4)) disable.push(true)
            } else {
                if (this.allActualLevels.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.allActualLevels.find(level => level.actualLevel.id_level === 1 && level.actualLevel.id_status !== 4)) disable.push(true)
            }
            if (this.allActualLevels.length < 1) disable.push(true)

            return disable.includes(true)
        },
    },

    beforeMount() {
        this.init()
    },

    methods: {
        async init() {
            this.listUserGroups()

            try {
                let filter = {
                    orderColumn: 'description',
                    textColumn: 'description',
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_module",
                            operator: "=",
                            value: 5
                        },
                        {
                            AndOr: "OR",
                            column: "id_planning_function",
                            operator: "=",
                            value: this.idPlanningFunction
                        },
                    ]
                }

                const eventOption = this.requiredOptions.find(option => option.column === 'id_event')
                const eventRes = await this.$http.post(eventOption.endpoint[0] + eventOption.endpoint[1] + '/list-options', { filter })
                if (eventRes.data.rows.length) {
                    eventOption.items = eventRes.data.rows
                    this.loadingEvents = false

                    this.noDataMessage = "Selecione um ou mais eventos ou importe um relatório existente para iniciar o planejamento"
                    this.noDataBtn = {
                        path: '/event',
                        text: 'Cadastrar evento'
                    }

                    filter = {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_status_type',
                                operator: '=',
                                value: 6
                            }
                        ]
                    }

                    const statusOption = this.options.find(option => option.column === 'id_status')
                    const statusRes = await this.$http.post(statusOption.endpoint[0] + statusOption.endpoint[1] + '/list-options', { filter })
                    if (statusRes) {
                        this.statusItems = statusRes.data.rows
                        statusOption.items = this.statusItems
                    }

                    filter = {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_sales_force_type',
                                operator: '=',
                                value: 6
                            }
                        ],
                    }

                    const salesForceOptions = this.options.find(option => option.column === 'id_sales_force')
                    const salesForceRes = await this.$http.post(salesForceOptions.endpoint[0] + salesForceOptions.endpoint[1] + '/list-options', { filter })
                    if (salesForceRes) {
                        this.salesForceItems = salesForceRes.data.rows
                        salesForceOptions.items = this.salesForceItems
                    }

                    filter = {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_sales_force_type',
                                operator: '=',
                                value: 7
                            }
                        ],
                    }

                    const salesForceBPOptions = this.options.find(option => option.column === 'id_sales_force_bp')
                    const salesForceBPRes = await this.$http.post(salesForceBPOptions.endpoint[0] + salesForceBPOptions.endpoint[1] + '/list-options', { filter })
                    if (salesForceBPRes) {
                        this.salesForceBPItems = salesForceBPRes.data.rows
                        salesForceBPOptions.items = this.salesForceBPItems
                    }

                    const customerGroupOptions = this.options.find(option => option.column === 'id_customer_group')
                    const customerGroupRes = await this.$http.post(customerGroupOptions.endpoint[0] + customerGroupOptions.endpoint[1] + '/list-options', { })
                    if (customerGroupRes) {
                        this.customerGroupItems = customerGroupRes.data.rows
                        customerGroupOptions.items = this.customerGroupItems
                    }

                    const roleOptions = this.options.find(option => option.column === 'id_role')
                    const roleRes = await this.$http.post(roleOptions.endpoint[0] + roleOptions.endpoint[1] + '/list-options', { })
                    if (roleRes) {
                        this.roleItems = roleRes.data.rows
                        roleOptions.items = this.roleItems
                    }

                    const hrClassificationOptions = this.options.find(option => option.column === 'id_hr_classification')
                    const hrClassificationRes = await this.$http.post(hrClassificationOptions.endpoint[0] + hrClassificationOptions.endpoint[1] + '/list-options', { })
                    if (hrClassificationRes) {
                        this.hrClassificationItems = hrClassificationRes.data.rows
                        hrClassificationOptions.items = this.hrClassificationItems

                        this.loading = false
                    }
                } else {
                    this.noDataMessage = "Nenhum evento encontrado. Cadastre um evento para iniciar o planejamento"
                    this.noDataBtn = {
                        path: '/event',
                        text: 'Cadastrar evento'
                    }

                    this.loading = false
                    return this.noEventsFound()
                }
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        },

        addDoubleClickHandler() {
            window.addEventListener('dblclick', this.handleDoubleClick)
        },

        removeDoubleClickHandler() {
            window.removeEventListener('dblclick', this.handleDoubleClick)
        },

        handleDoubleClick() {
            if (this.selectedRowDuplicate) {
                this.newEmployeeAllocationDialog = true
            }
        },

        calculateAccumulatedAllocation(employeeId, col) {
            let filteredEmployee = this.objectData.filter(i => i.id_employee === employeeId)
            if (filteredEmployee.length) {
                return filteredEmployee.reduce((acc, cur) => {
                    return cur[col] ? acc + parseInt(cur[col] * 100, 10) : acc + 0
                }, 0)
            } else {
                return 0
            }
        },

        removeAllocationColors(className) {
            if (!className) {
                return
            }

            let classNames = className.split(' ')
            let errorIndex = classNames.findIndex(i => i.includes('allocation-error-cell'))
            if (errorIndex > -1) classNames.splice(errorIndex, 1)
            let warningIndex = classNames.findIndex(i => i.includes('allocation-warning-cell'))
            if (warningIndex > -1) classNames.splice(warningIndex, 1)

            return classNames.join(' ')
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (const element of this.getMenu) {
                let submenu = element.menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = element.icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        setCostCenterSource(customerGroupId) {
            return this.costCenterItems.filter(c => c.id_customer_group === customerGroupId)
        },

        setProductSource(filteredCostCenters) {
            if (filteredCostCenters.length) {
                let productIds = filteredCostCenters.map(f => f.id_product)
                return this.productItems.filter(i => productIds.includes(i.id)).map(p => p.text)
            } else {
                return this.productItems.map(p => p.text)
            }
        },

        closeNewEmployeeAllocationDialog(savedChanges) {
            this.newEmployeeAllocationDialog = false

            if (savedChanges) {
                this.setRefresh()
            }
        },

        closeNewEmployeeDialog(savedChanges) {
            this.newEmployeeDialog = false

            if (savedChanges) {
                this.setRefresh()
            }
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        async getCostCenters() {
            let payload = {
                showAdditionalData: true,
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_company_group',
                            operator: '=',
                            value: 1
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center_type',
                            operator: '=',
                            value: 1
                        },
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_event_type',
                        //     operator: '=',
                        //     value: this.event.id_event_type
                        // },
                        // {
                        //     AndOr: 'AND',
                        //     column: 'fiscal_year',
                        //     operator: '=',
                        //     value: this.event.fiscal_year
                        // },
                    ]
                }
            }

            const costCenterOptions = this.options.find(option => option.column === 'id_cost_center')
            const costCenterRes = await this.$http.post(costCenterOptions.endpoint[0] + costCenterOptions.endpoint[1] + '/list-options-employee-allocation-planning', { ...payload })
            if (costCenterRes) {
                this.costCenterItems = costCenterRes.data.rows
                costCenterOptions.items = this.costCenterItems
            }
        },

        async getProducts() {
            const productOptions = this.options.find(option => option.column === 'id_product')
            this.productItems = []
            productOptions.items = this.productItems

            let filter = {
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'fiscal_year',
                        operator: '=',
                        value: this.event.fiscal_year
                    },
                    {
                        AndOr: 'AND',
                        column: 'id_event_type',
                        operator: '=',
                        value: this.event.id_event_type
                    },
                    {
                        AndOr: 'AND',
                        column: 'planning_by_product',
                        operator: '=',
                        value: 1
                    }
                ],
            }

            if (this.planningGroupOption) {
                if (this.planningGroupOption.id !== 15) {
                    filter.conditions.push({
                        AndOr: 'AND',
                        column: 'id_planning_group',
                        operator: '=',
                        value: this.planningGroupOption.id
                    })
                }
            }

            const productRes = await this.$http.post(productOptions.endpoint[0] + productOptions.endpoint[1] + '/list-options', { filter })
            if (productRes) {
                this.productItems = productRes.data.rows
                productOptions.items = this.productItems
            }
        },

        async loadOptionItems(option) {
            if (option.column === 'id_cost_center' || option.column === 'id_status' ||
                option.column === 'hr_classification' || option.column === 'id_customer_group' ||
                option.column === 'id_role' || option.column === 'id_sales_force' ||
                option.column === 'id_sales_force_bp' || option.column === 'id_product'
            ) {
                return
            }

            this.loadingOptionItems.push(option.label)

            let filter = null
            if (option.filterable) {
                filter = {
                    conditions: option.conditions
                }
            }

            if (option.dynamicFilter) {
                if (option.column === 'id_employee') {
                    filter.conditions = []
                    filter.conditions.push(
                        {
                            AndOr: 'AND',
                            column: 'input_planning',
                            operator: '=',
                            value: 1
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_company_group',
                            operator: '=',
                            value: this.event.id_company_group,
                        }
                    )

                    if (this.filterExitDate) {
                        const fiscalYear = this.event.fiscal_year
    
                        filter.conditions.push(
                            {
                                AndOr: "AND",
                                column: "admission_date",
                                operator: "<=",
                                value: `${fiscalYear}-12-31`,
                            },
                            {
                                AndOr: "OR",
                                column: "exit_date",
                                operator: "IS",
                                value: null,
                            },
                            {
                                AndOr: "OR",
                                column: "exit_date",
                                operator: ">=",
                                value: `${fiscalYear}-01-01`,
                            }
                        )
                    }
                } else {
                    filter.conditions.forEach(c => {
                        if (c.getValueFrom) {
                            c.value = this[c.getValueFrom][c.column]
                        }
                    })
                }
            }

            try {
                let res = await this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { filter })
                if (res) {
                    let foundOption = this.options.find(o => o.label === option.label)
                    foundOption.items = res.data.rows

                    this.loadingOptionItems.pop()
                }
            } catch (err) {
                this.loadingOptionItems.pop()
                this.$fnError(err)
            }
        },

        isLoadingOptionItems(option) {
            if (this.loadingOptionItems.includes(option.label)) {
                return true
            } else {
                return false
            }
        },

        setSelectedFilter(filter, e) {
            let index = this.filterConditions.findIndex(f => f.option === filter.column)

            if (index > -1) {
                if ((filter.multiple && !e.length) || (!filter.multiple && !e)) {
                    this.filterConditions.splice(index, 1)
                } else {
                    this.filterConditions[index].selectedItems = e
                }
            } else {
                this.filterConditions.push({ option: filter.column, selectedItems: e }) 
            }
        },

        async getAnalyseHeaders() {
            this.loading = true

            this.lastRefreshParams = {
                event: this.event
            }

            let payload = { queryType: 'SELECT_ALL' }

            try {
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/analyse-headers', { ...payload })
                if (res) {
                    this.analyseHeaders = res.data.headers

                    this.setDateColumns()
                    this.refresh()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setFilterConditions() {
            let filterConditions = []

            this.filterConditions.forEach(c => {
                if (c.selectedItems.length) {
                    filterConditions.push(
                        {
                            AndOr: "AND",
                            column: c.option,
                            operator: "IN",
                            value: c.selectedItems.map(i => {
                                return c.option === 'id_product' ? i.id_product : i.id
                            })
                        }
                    )
                } else {
                    filterConditions.push(
                        {
                            AndOr: "AND",
                            column: c.option,
                            operator: "=",
                            value: c.selectedItems.text
                        }
                    )
                }
            })

            return filterConditions
        },

        setSelectedDisplayFilters() {
            this.selectedDisplayFilters = this.options.filter(o => o.visible)
        },

        async loadGrid() {
            this.loading = true
            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null },

            this.selectedRoleItems = []
            this.selectedStatusItems = []
            this.selectedCostCenterItems = []
            this.selectedProductItems = []
            this.selectedCustomerGroupItems = []
            this.selectedRoleItems = []
            this.selectedHrClassificationItems = []

            if (this.disableLoadGrid) {
                return this.loading = false
            }

            if (this.planningGroupOption) {
                this.selectedEvent = this.event.id
                this.selectedPlanningGroup = this.planningGroupOption.id
                if (this.filterConditions.find(filter => filter.option === 'id_product')?.selectedItems[0]) {
                    this.selectedProduct = this.filterConditions.find(filter => filter.option === 'id_product').selectedItems[0].id_product
                    this.productSelected = this.selectedProduct
                }
                this.levels = []
                this.approvals = []
                if (this.selectedProduct) await this.approvalListLevels()
                if (this.selectedProduct) await this.approvalHistory()
                await this.setAllLevels()
            } else {
                this.selectedPlanningGroup = null
            }
            
            this.disableEdition = false
            this.readOnlyRules = []
            if (this.hasMultipleSelection || this.hasEmptyRequiredFilter || this.isNotShowingRequiredColumn) {
                this.disableEdition = true
            }

            this.readOnly = this.disableEdition || this.readOnlyApproval
            if (this.readOnly) this.setReadOnlyRules()

            this.payload.id_event = this.event.id
            this.payload.id_account_group_version = this.event.id_account_group_version
            this.payload.id_planning_group_version = this.event.id_planning_group_version
            this.year1 = this.event.fiscal_year
            this.yearMonthInputStart = this.event.year_month_input_start
            this.fiscalYearStartMonth = this.event.fiscal_year_start_month
            this.startingMonthIndex = this.event.fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            this.options.forEach(o => {
                let index = this.analyseHeaders.findIndex(h => h.columnId === o.column)
                if (index > -1) this.analyseHeaders[index].visible = o.visible
            })
            
            for (let i = 13; i <= 24; i++) {
                let index = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth || !this.year2month) {
                    index = this.analyseHeaders.findIndex(c => c.name === `value${i}`)
                }

                if (index && index > -1) {
                    this.analyseHeaders[index].visible = false
                    this.analyseHeaders[index].hideColumn = true
                }
            }

            let payload = {
                id_event: this.payload.id_event,
                columns: this.analyseHeaders,
                filter: { conditions: [] },
                filterExitDate: this.filterExitDate
            }

            payload.filter.conditions = this.setFilterConditions()
            this.setSelectedDisplayFilters()

            if (this.planningGroupOption) {
                if (this.planningGroupOption.id === 15) {
                    payload.filter.conditions.push({
                        AndOr: 'OR',
                        column: 'id_product',
                        operator: 'IS',
                        value: null
                    })

                    payload.filter.conditions.push({
                        AndOr: 'OR',
                        column: 'id_product',
                        operator: '=',
                        value: 65
                    })
                } else {
                    payload.filter.conditions.push({
                        AndOr: 'AND',
                        column: 'id_planning_group',
                        operator: '=',
                        value: this.planningGroupOption.id
                    })
                }
            }

            try {
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/list', { ...payload })
                if (res) {
                    let _colHeaders = []
                    let _columns = []
                    let resColumns = res.data.columns

                    res.data.headers.forEach((header) => {
                        let headerIndex = parseInt(header.value.slice(5, 7), 10)
                        let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)
                        if (columnIndex === -1) return

                        let headerText = header.text
                        if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            if (header.value === 'value00') {
                                let values = header.text.split(' ')
                                headerText = `${values[0]} ${values[1] || ''}`
                            }

                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }

                        if (header.value === "cd_employee") {
                            headerText = "ID Externa </br> (Matrícula)"
                        }
                        _colHeaders.push(headerText)

                        let readOnly = false
                        this.readOnly ? readOnly = true: readOnly = false

                        if (header.editable === false) {
                            readOnly = true
                        }

                        let type = null
                        let className = ''
                        let numericFormat = null
                        let source = null
                        if ((header.options && header.value === 'status') || header.value === 'product_name'
                            || header.value === 'role' || header.value === 'hr_classification'
                            || header.value === 'exclude_from_ratio'
                        ) {
                            type = 'dropdown'
                            source = this.setSelectOptions(header)
                        } else if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else {
                            type = 'text'
                            className += ' htLeft'
                        }

                        let hide = false
                        if (header.hideColumn) {
                            hide = true
                        }

                        let visible = false
                        if (header.visible) {
                            visible = true
                        }

                        _columns.push({ data: header.value, readOnly, type, className, numericFormat, source, hide, visible, columnLetter: header.columnLetter })
                    })
                    this.colHeaders = _colHeaders
                    this.columns = _columns

                    this.hiddenColumns.columns = []
                    this.setHiddenColumns()
                    this.setDateReadOnlyColumns()

                    let rowHeaders = []
                    let objectData = []

                    res.data.rows.forEach((row, index) => {
                        rowHeaders.push('')

                        let rowProps = {
                            index,
                            row,
                            className: '',
                            format: row.format,
                            readOnly: false,
                        }

                        let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                            return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                        }, 0)

                        if (subtotalsQty > 1) {
                            let counter = 0
                            for (const field in row) {
                                if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                    if (counter > 0) {
                                        row[field] = ''
                                    }

                                    counter += 1
                                }
                            }
                        }

                        if (row.admission_date) {
                            row.admission_date = moment(row.admission_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.exit_date) {
                            row.exit_date = moment(row.exit_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.indent) {
                            rowProps.className += ' indentedRow'
                            row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                        }

                        for (let key in row) {
                            if (row.employee_name === "#TOTAL") {
                                break
                            }

                            if (key.includes('value') && key !== 'value00') {
                                row[key] = parseFloat(row[key], 10) || null
                            } else if (key === 'comment' && row[key] === "") {
                                row[key] = null
                            } else if (key === 'exclude_from_ratio') {
                                if (row[key] === 1) {
                                    row[key] = 'Sim - Todos'
                                } else if (row[key] === 2) {
                                    row[key] = 'Sim - Apenas ADM/INV'
                                } else if (row[key] === 3) {
                                    row[key] = 'Sim - Apenas INFRA'
                                } else {
                                    row[key] = 'Não'
                                }
                            }
                        }

                        if (row.css) {
                            rowProps.className += ` ${row.css}`
                        }

                        this.stylizedRows.push(rowProps)
                        
                        objectData.push(row)
                    })

                    this.rowHeaders = rowHeaders
                    this.objectData = objectData

                    if (!this.objectData.length) {
                        this.noDataMessage = 'Não há dados disponíveis'
                    }

                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        setSelectOptions(header) {
            if (header.value === 'status') {
                return this.statusItems.map(s => s.text)
            // } else if (header.value === 'cd_cost_center') {
            //     return this.costCenterItems.map(c => c.cd_cost_center)
            } else if (header.value === 'product_name') {
                return this.productItems.map(p => p.text)
            // } else if (header.value === 'customer_group') {
            //     return this.customerGroupItems.map(c => c.text)
            } else if (header.value === 'role') {
                return this.roleItems.map(r => r.text)
            } else if (header.value === 'hr_classification') {
                return this.hrClassificationItems.map(h => h.text)
            } else if (header.value === 'exclude_from_ratio') {
                return ['Não', 'Sim - Todos', 'Sim - Apenas ADM/INV', 'Sim - Apenas INFRA']
            }
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month = true
            this.year1quarter = false
            this.year1semester = false
            this.laterYear = false
            this.year2month = false
            this.year2quarter = false
            this.year2semester = false
            
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            // this.year2months = this.months
            // this.year1quarters = this.quarters
            // this.year2quarters = this.quarters
            // this.year1semesters = this.semesters
            // this.year2semesters = this.semesters
        },

        handleSetAllHiddenColumns() {
            let found = this.options.find(o => o.visible)

            if (found) {
                this.options.forEach(o => {
                    o.visible = false
                })
                this.showColorAllocation = false
            } else {
                this.options.forEach(o => {
                    o.visible = true
                })
                this.showColorAllocation = true
            }
        },

        setHiddenColumns() {
            this.columns.forEach((column, index) => {
                if (column.hide || !column.visible) {
                    if (index && !this.hiddenColumns.columns.includes(index)) {
                        this.hiddenColumns.columns.push(index)
                    }
                }
            })

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth || !this.year1month) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth || !this.year2month) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter || !this.year1quarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter || !this.year2quarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year1semester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year2semester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col > -1) {
                    if (col && !this.hiddenColumns.columns.includes(col)) {
                        this.hiddenColumns.columns.push(col)
                    }
                }
            }
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        foundColumn.className += ' read-only-column'
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        validateRequiredFields(row) {
            const requiredFields = ['id_event', 'id_employee', 'id_sales_channel', 'id_product', 'id_cost_center', 'id_customer_group']

            let isValid = { value: true, field: null, row: null }

            for (const field of requiredFields) {
                if (!this.objectData[row][field]) {
                    isValid.value = false
                    isValid.field = field
                    isValid.row = row + 1
                    break
                }
                
            }

            return isValid
        },

        async save() {
            this.saving = true
            let payload = { items: [] }
            let isValid = { value: true, field: null, row: null }

            for (const row of this.editedRows) {
                if (this.planningGroupOption && this.planningGroupOption.id === 15 && !this.objectData[row].id_product) {
                    continue
                }

                let excludeFromRatio

                if (this.objectData[row].exclude_from_ratio === 'Sim - Todos') {
                    excludeFromRatio = 1
                } else if (this.objectData[row].exclude_from_ratio === 'Sim - Apenas ADM/INV') {
                    excludeFromRatio = 2
                } else if (this.objectData[row].exclude_from_ratio === 'Sim - Apenas INFRA') {
                    excludeFromRatio = 3
                } else {
                    excludeFromRatio = 0
                }

                isValid = this.validateRequiredFields(row)
                if (!isValid.value) {
                    break
                }

                payload.items.push({
                    ...this.objectData[row],
                    exclude_from_ratio: excludeFromRatio,
                })
            }

            if (!isValid.value) {
                this.$toast.error(`Linha: ${isValid.row} - Campo ${isValid.field} é obrigatório`)
                this.saving = false
                return
            }

            // if (this.planningGroupOption && this.planningGroupOption.id !== 15) {
            //     payload.filter = {
            //         conditions: [{
            //             AndOr: "AND",
            //             column: "id_planning_group",
            //             operator: "=",
            //             value: this.selectedPlanningGroup
            //         }]
            //     }
            // }

            try {
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.refresh()
                    this.saving = false
                    this.$toast.success(res.data.msg)
                    this.editedRows = []
                    this.selectedRowDuplicate = null
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        },

        setRefresh() {
            if (!this.unsavedChanges) {
                if (this.lastRefreshParams) {
                    if (this.lastRefreshParams.event.id === this.event.id) {
                        this.refresh()
                    } else {
                        this.getAnalyseHeaders()
                    }
                } else {
                    this.getAnalyseHeaders()
                }
            } else {
                this.dialogRefreshGrid = true
            }
        },

        async refresh(save = false) {
            this.loading = true
            this.dialogRefreshGrid = false
            this.selectedRowDuplicate = null
            this.editedRows = []

            if (save) {
                let payload = { items: [] }

                for (let i = 0; i < this.objectData.length; i++) {
                    let object = {}
                    this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                        object[this.columns[index].data] = field
                    })

                    for (const row in this.payload) {
                        object[row] = this.payload[row]
                    }

                    payload.items.push(object)
                }

                if (this.planningGroupOption) {
                    payload.filter = {
                        conditions: [{
                            AndOr: "AND",
                            column: "id_planning_group",
                            operator: "=",
                            value: this.planningGroupOption.id
                        }]
                    }
                }

                try {
                    const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-save', { ...payload })
                    if (res) {
                        this.editedRows = []
                        this.hotInstance = null
                        this.loadGrid()
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                this.hotInstance = null
                this.loadGrid()
            }
        },

        closeGrid() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            }
        },

        setReadOnlyRules() {
            if (!this.options.find(i => i.column === 'id_employee').visible) {
                this.readOnlyRules.push('Exiba a coluna de Nome')
            }

            if (!this.options.find(i => i.column === 'id_product').visible) {
                this.readOnlyRules.push('Exiba a coluna de Nome do projeto/produto')
            }

            if (this.readOnlyApproval) {
                if (this.actualLevel && this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4) {
                    this.readOnlyRules.push('Nível de aprovação não permite alterações')
                } else {
                    this.readOnlyRules.push('Evento com todos os níveis aprovados')
                }
            }
        },

        setSelectEvent() {
            if (!this.unsavedChanges) {
                this.selectEvent(false)
            } else {
                this.dialogSelectEvent = true
            }
        },

        closeDialogSelectEvent() {
            this.event = this.backupSelectEvent
            this.dialogSelectEvent = false
        },

        async selectEvent(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectEvent = false
            this.loading = true

            this.payload.id_event = this.event.id

            this.payload.id_account_group_version = this.event.id_account_group_version
            this.payload.id_planning_group_version = this.event.id_planning_group_version
            this.year1 = this.event.fiscal_year
            this.yearMonthInputStart = this.event.year_month_input_start
            this.fiscalYearStartMonth = this.event.fiscal_year_start_month
            this.startingMonthIndex = this.event.fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            this.getCostCenters()

            this.getProducts()
    
            this.setRefresh()
        },

        async selectPlanningGroup() {
            return this.getProducts()
        },

        noEventsFound() {
            this.event = -1
            this.payload.id_event = null

            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
            return this.loadingEvents = false
        },

        async exportSheet(){
            this.exporting = true

            let columnsToRemove = this.setColumnsToRemove()

            let payload = {
                id_event: this.payload.id_event,
                columnsToRemove,
                filter: { conditions: [] }
            }

            this.filterConditions.forEach(c => {
                if (c.selectedItems.length) {
                    payload.filter.conditions.push(
                        {
                            AndOr: "AND",
                            column: c.option,
                            operator: "IN",
                            value: c.selectedItems.map(i => {
                                return c.option === 'id_product' ? i.id_product : i.id
                            })
                        }
                    )
                } else {
                    payload.filter.conditions.push(
                        {
                            AndOr: "AND",
                            column: c.option,
                            operator: "=",
                            value: c.selectedItems.text
                        }
                    )
                }
            })

            if (this.planningGroupOption) {
                payload.filter.conditions.push({
                    AndOr: "AND",
                    column: "id_planning_group",
                    operator: "=",
                    value: this.planningGroupOption.id
                })
            }

            try {
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            for (let i = 0; i < this.options.length; i++) {
                if (!this.options[i].visible) {
                    columnsToRemove.push(this.options[i].columnToRemove)
                }
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning' + '/mass-effect', { ...uploadRes.data.id, isSpreadsheetUpload: true })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh()
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        async approvalSelect (option) {
            const payload = {
                items: [
                    {
                        id_event: this.selectedEvent,
                        id_planning_group: this.selectedPlanningGroup,
                        id_product: this.productSelected,
                        justification: this.justification
                    }
                ]
            }

            if (!payload.items[0].id_event || !payload.items[0].id_planning_group || !payload.items[0].id_product) {
                return
            }

            try {
                this.loadingApproval = true
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.loadingApproval = false
            this.justification = ''
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.selectedPlanningGroup
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_product',
                            operator: '=',
                            value: this.selectedProduct
                        }
                    ]
                }
            }

            if (payload.filter.conditions.find(condition => !condition.value)) {
                return
            }
            
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                this.levels = []

                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel = this.levels[this.levels.length - 1] : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    this.getlevels()
                } else {
                    this.readOnlyApproval = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.selectedPlanningGroup
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_product',
                            operator: '=',
                            value: this.selectedProduct
                        }
                    ]
                }
            }

            if (payload.filter.conditions.find(condition => !condition.value)) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4){
                        this.readOnlyApproval = this.actualLevel.readOnly
                        this.readOnlyApproval === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnlyApproval = true : this.readOnlyApproval = false
                        if (this.readOnlyApproval) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        closeDialogApprovalAll () {
            if (this.doRefresh) {
                this.dialogApprovalAll = false
                this.doRefresh = false
                this.refresh()
            } else {
                this.dialogApprovalAll = false
            }
        },

        async approveAll (option) {
            const groupToApproval = this.selected.length > 0 ? this.selected : this.allActualLevels
            this.loadingApproval = true
            try {
                const payload = { items: [] }
                groupToApproval.forEach((product) => {
                    payload.items.push({
                        id_event: this.selectedEvent,
                        id_planning_group: this.planningGroupOption.id,
                        id_product: product.id_product,
                        justification: this.justification
                    })
                })
                if (option === 1) {
                    await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                } else {
                    await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                }
                this.$toast.success('Grupos de planejamento aprovados com successo')
            } catch (err) {
                this.$fnError(err)
            }
            this.loadingApproval = false
            this.justification = ''
            this.selected = []
            this.selectAllToApprove = false
            this.selectAllToReprove = false
            this.refresh()
        },

        async setAllLevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.planningGroupOption.id
                        }
                    ]
                }
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    let levels = res.data.rows
                    let products = this.productItems.filter( product => product.id_planning_group === this.selectedPlanningGroup )
                    products = products.map(product => ({ ...product, levels: levels.filter( level => level.id_product === product.id_product) }))
                    products = products.map(product => ({ ...product, unapprovedLevels: product.levels.filter( level => level.id_status === 5 || !level.id_status) }))
                    products = products.map(product => ({
                        ...product,
                        // VERIFICA SE TEM NÍVEL POR APROVAR
                        actualLevel: product.unapprovedLevels.length > 0 ?
                            { ...product.unapprovedLevels[0], enable: this.userGroups.find(group => group.id_group === product.unapprovedLevels[0].id_group ) ? true : false } :
                            // VERIFICA SE TODOS NÍVEIS ESTÃO APROVADOS
                            product.levels.length > 0 ?
                                { ...product.levels[product.levels.length - 1], enable: this.userGroups.find(group => group.id_group === product.levels[product.levels.length - 1].id_group) ? true : false  } :
                                //NÃO TEM NÍVEL CADASTRADO
                                { approval_flow_level_description: 'Nenhum', enable: false }
                        //O ENABLE HABILITA OU DESABILITA BOTÃO DE APROVAR E DESAPROVAR
                    }))
                this.allActualLevels = products
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async updateMonths () {
            const payload = {
                id_event: this.event.id,
            }
            try {
                this.loadingUpdateMonths = true
                const res = await this.$http.post(this.$ipSalesPlanning + 'employee-allocation-planning/copy-from-actual', { ...payload })
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.loadingUpdateMonths = false
                    this.updateMonthsDialog = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingUpdateMonths = false
            }
            this.loadingUpdateMonths = false
            this.updateMonthsDialog = false
            this.refresh()
        },

        closeDialogCopyData() {
            this.dialogCopyData = false
            this.copySaving = false
            this.copyDataEvents = []
            this.clearCopyDataEvent()
            this.copyDataBPs = []
            this.selectedCopyDataBP = null
            this.copyDataProjects = []
            this.selectedCopyDataProject = null
        },

        async openDialogCopyData() {
            this.dialogCopyData = true
            this.getCopyEvents()
        },

        async selectCopyDataEvent() {
            this.getCopyBPs()
            this.getCopyProjects()
        },
        
        clearCopyDataEvent() {
            this.selectedCopyDataEvent = null
        },

        async selectCopyDataBP() {
            this.getCopyProjects()
        },

        async getCopyEvents() {
            this.loadingCopyDataEvents = true

            try {
                const payload = {
                    filter: {
                        orderColumn: 'description',
                        textColumn: 'description',
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 5
                            },
                            {
                                AndOr: "AND",
                                column: "id_status",
                                operator: "NOT LIKE",
                                value: 2
                            },
                            {
                                AndOr: "AND",
                                column: "id_planning_function",
                                operator: "IN",
                                value: [3, 16]
                            },
                        ]
                    }
                }
                const res = await this.$http.post(`${this.$ipEvent}event/list-options`, payload)
                if (res) {
                    this.copyDataEvents = res.data.rows
                    this.loadingCopyDataEvents = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingCopyDataEvents = false
            }
        },

        async getCopyBPs() {
            this.loadingCopyDataBPs = true
            
            try {
                const payload = {
                    salesForceColumn: "id_sales_force_bp",
                    filter: {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_event',
                                operator: '=',
                                value: this.selectedCopyDataEvent.id,
                            },
                        ]
                    }
                }
                
                const res = await this.$http.post(`${this.$ipSales}sales-force/list-options-sales-planning`, payload)
                if (res) {
                    this.copyDataBPs = res.data.rows
                    this.loadingCopyDataBPs = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingCopyDataBPs = false
            }
        },

        async getCopyProjects() {
            this.loadingCopyDataProjects = true

            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_event',
                                operator: '=',
                                value: this.selectedCopyDataEvent.id,
                            },
                        ]
                    }
                }

                if (this.selectedCopyDataBP) {
                    payload.filter.conditions.push({
                        AndOr: 'AND',
                        column: 'id_sales_force_bp',
                        operator: '=',
                        value: this.selectedCopyDataBP.id,
                    })
                }
                
                const res = await this.$http.post(`${this.$ipProduct}product/list-options-sales-planning`, payload)
                if (res) {
                    this.copyDataProjects = res.data.rows
                    this.loadingCopyDataProjects = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingCopyDataProjects = false
            }
        },

        async handleCopySave() {
            this.copySaving = true

            const eventId = this.selectedCopyDataEvent ? this.selectedCopyDataEvent.id : null
            const bpId = this.selectedCopyDataBP ? this.selectedCopyDataBP.id : null
            const projectId = this.selectedCopyDataProject ? this.selectedCopyDataProject.id : null
            
            try {
                const payload = {
                    id_event: this.event.id,
                    id_event_actual: eventId,
                    id_sales_force_bp: bpId,
                    id_product: projectId,
                }

                const res = await this.$http.post(`${this.$ipSalesPlanning}sales-planning-customer/copy-data`, payload)
                if (res.data) {
                    this.$toast.success(res.data.msg)
                    this.refresh()
                    this.closeDialogCopyData()
                }
            } catch (err) {
                this.$fnError(err)
                this.copySaving = false
            }
        }
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
