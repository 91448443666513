var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"position":"relative","overflow":"hidden !important"}},[_c('v-card-title',{staticClass:"light--text",staticStyle:{"position":"sticky","top":"0","width":"100%","z-index":"1000"},style:(("background: linear-gradient(90deg, " + (_vm.$vuetify.theme.themes.light.primary) + " 0%, " + (_vm.$vuetify.theme.themes.light.secondary) + " 100%)"))},[_c('span',{ref:"formTitle",staticClass:"headline"},[_vm._v("Nova alocação")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-4 pa-0",attrs:{"dark":"","text":"","min-width":"48px","disabled":_vm.loading},on:{"click":function($event){return _vm.insertNewRow()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"dark":"","text":"","min-width":"48px","disabled":_vm.loading || _vm.disableDelete},on:{"click":function($event){return _vm.deleteSelectedRow()}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Deletar linha")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"dark":"","text":"","min-width":"48px","disabled":_vm.loading || _vm.disableSave},on:{"click":function($event){return _vm.save()}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Salvar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"dark":"","text":"","min-width":"48px","loading":_vm.importing,"disabled":_vm.loading},on:{"click":function($event){return _vm.importSheet()}}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onFileChanged}})]}}])},[_c('span',[_vm._v("Importar Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mr-4 pa-0",attrs:{"dark":"","text":"","min-width":"48px","loading":_vm.exporting,"disabled":_vm.loading},on:{"click":function($event){return _vm.exportSheet()}}},on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 pa-0",attrs:{"dark":"","text":"","min-width":"48px"},on:{"click":_vm.closeNewEmployeeAllocationDialog}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[(_vm.loading)?_c('v-row',{staticClass:"my-4"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-sheet',{staticClass:"ma-0 pa-0 d-flex justify-center align-center",attrs:{"height":_vm.windowSize.y - 64 - 270}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","small":""}})],1)],1)],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('v-sheet',{staticClass:"rounded-b px-4 py-4"},[_c('HotTable',{ref:"hotTableNewEmployeeAllocation",attrs:{"licenseKey":"1c81c-153e1-cb283-a4838-af03f","data":_vm.filteredData,"settings":_vm.settings,"columns":_vm.filteredColumns,"colHeaders":_vm.filteredColHeaders,"rowHeaders":true,"hiddenColumns":_vm.hiddenColumns,"hiddenRows":_vm.hiddenRows,"contextMenu":_vm.contextMenu,"dropdownMenu":_vm.dropDownMenu,"manualColumnResize":true,"multiColumnSorting":true,"bindRowsWithHeaders":true,"filters":true,"height":_vm.windowSize.y - 64 - 250,"width":"100%","columnHeaderHeight":50,"rowHeaderWidth":50,"trimWhitespace":false,"copyPaste":true,"stretchH":"last","renderAllRows":false}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }